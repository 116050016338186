import { Component } from 'react'

export default class StickyProvider extends Component {
  state = {
    isSticky: false,
    ref: null
  }

  getScrollPosition = () => {
    const panelPosition = this.state.ref.getBoundingClientRect().top
    if (panelPosition <= 0) {
      this.setState({
        isSticky: true
      })
    } else {
      this.setState({
        isSticky: false
      })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.getScrollPosition)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.getScrollPosition)
  }
  setRef = ref => {
    this.setState({
      ref
    })
  }
  render() {
    return this.props.render(this.setRef, this.state.isSticky)
  }
}

import { Component } from 'react'

export default class InfiniteScrollProvider extends Component {
  state = {
    offset: 0
  }

  componentDidMount() {
    window.addEventListener(`scroll`, this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this.onScroll)
  }

  onScroll = () => {
    const { perPage, total } = this.props
    const { scrollY, innerHeight } = window
    const { offsetHeight } = document.documentElement

    const toBottom = offsetHeight - (scrollY + innerHeight)
    const nextOffset = this.state.offset + perPage

    if (toBottom < 150 && nextOffset < total) {
      this.setState({ offset: nextOffset })
    }
  }

  render() {
    return this.props.render(this.state.offset)
  }
}

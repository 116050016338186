import { Component } from 'react'

class DelayVisibilityProvider extends Component {
  state = {
    isVisible: false
  }
  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({ isVisible: true })
    }, this.props.delay)
  }
  componentWillUnmount() {
    clearTimeout(this.timeout)
  }
  render() {
    return this.props.render(this.state.isVisible)
  }
}

export default DelayVisibilityProvider

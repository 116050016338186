import React from 'react'
import CategoriesPanel from '../components/CategoriesPanel'
import CarImageHeader from '../components/CarImageHeader'
import ArticleThumbnail from '../components/ArticleThumbnail'
import styles from './ArticlesIndex.module.css'
import { mapMardkownRemarkToArticle } from '../helpers'
import DelayVisibilityProvider from '../containers/DelayVisibilityProvider'
import InfiniteScrollProvider from '../containers/InfiniteScrollProvider'

const getColumnsByIndex = index => {
  if (index === 0) {
    return 1
  } else if (
    index === 1 ||
    index === 6 ||
    ((index - 1) % 5 === 0 && index !== 6)
  ) {
    return 2
  }
  return 3
}

const perPage = 8

const ArticlesIndex = ({
  selectedCategory,
  categories,
  articles,
  selectedTag
}) => (
  <div>
    <CarImageHeader />
    <CategoriesPanel
      categories={categories}
      selectedCategory={selectedCategory}
    />

    <InfiniteScrollProvider
      total={articles.length}
      perPage={perPage}
      render={offset => (
        <div className={styles.articlesContainer}>
          {articles
            .slice(0, offset + perPage)
            .map((article, i) => (
              <DelayVisibilityProvider
                delay={(i + 1 - offset) * 300}
                key={article.node.fields.slug}
                render={isVisible => (
                  <ArticleThumbnail
                    {...mapMardkownRemarkToArticle(article)}
                    columns={getColumnsByIndex(i)}
                    selectedTag={selectedTag}
                    isVisible={isVisible}
                  />
                )}
              />
            ))}
        </div>
      )}
    />
  </div>
)
export default ArticlesIndex

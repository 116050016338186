import React from 'react'
import Link from 'gatsby-link'
import classNames from './CategoriesPanel.module.css'
import StickyProvider from '../containers/StickyProvider'

import { RADAR_DATA_FILE_NAME } from './TechRadar/latest'

import radarLinkImg from '../img/radar-link.svg'

const CategoriesPanel = ({ categories, selectedCategory = 'All' }) => (
  <StickyProvider
    render={(setRef, isSticky) => {
      const innerPanelContainerClasses = [classNames.innerPanelContainer]
      if (isSticky) {
        innerPanelContainerClasses.push(classNames.fixedPanel)
      }
      return (
        <div ref={setRef} className={classNames.categoriesPanelContainer}>
          <div className={innerPanelContainerClasses.join(' ')}>
            <div className={classNames.panel}>
              {['All', ...categories].map((category) => (
                <Link
                  key={category}
                  className={`${classNames.category} ${
                    selectedCategory === category ? classNames.categorySelected : ''
                  }`}
                  to={category === 'All' ? '/' : `/categories/${encodeURIComponent(category)}`}
                >
                  {category}
                </Link>
              ))}
            </div>
            <div className={classNames.radarLinkContainer}>
              <Link to={`/${RADAR_DATA_FILE_NAME}`}>
                <div className={classNames.techRadarContainer}>
                  <span style={{ marginRight: '8px' }}>
                    <img src={radarLinkImg} alt="radar-link" />
                  </span>
                  <span>Technology radar</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default CategoriesPanel
